import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { Environment } from '@src/environments/environment'

interface Me {
  first_name: string
  last_name: string
  email: string
  count_member_of_structure: number
  count_member_request_pending: number
}

@Injectable({
  providedIn: 'root',
})
export class MeService {
  private apiPath: string = Environment.API_PATH

  constructor(private http: HttpClient) { }

  getMe(): Observable<Me> {
    return this.http.get<Me>(`${this.apiPath}/v1/me`).pipe(
      catchError(() => {
        return throwError(() => 'Impossible de charger les informations de l\'utilisateur')
      })
    )
  }
}
