import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { MeService } from '@src/services/me.service'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StructureGuard implements CanActivate {
  constructor(
    private meService: MeService,
    private router: Router,
    private http: HttpClient
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const data = await firstValueFrom(this.meService.getMe())
    if (data.count_member_of_structure == 0 && data.count_member_request_pending == 0) {
      this.http.get('api/public/v1/drafts/structure/latest').subscribe({
        next: (draft) => {
          this.router.navigate(['/structure/enregistrer'], { state: { draft } })
          return false
        },
        error: () => {
          this.router.navigate(['/structure/rattachement'])
          return false
        },
      })
    } else if (data.count_member_of_structure == 0 && data.count_member_request_pending > 0) {
      this.router.navigate(['/structure/demande-rattachement-en-attente'])
      return false
    }

    return true
  }
}
